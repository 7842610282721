var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.didLoadData
    ? _c("div", { staticClass: "nextcloud-widget nextcloud-system-wrapper" }, [
        _c("div", { staticClass: "charts" }, [
          _c(
            "div",
            { staticClass: "chart-container" },
            [
              _c("small", [
                _vm._v(
                  _vm._s(_vm.tt("overall")) +
                    " " +
                    _vm._s(_vm.tt("memory-utilisation"))
                ),
              ]),
              _c(
                "GaugeChart",
                {
                  attrs: {
                    value: _vm.memoryGauge.value,
                    baseColor: _vm.memoryGauge.background,
                    gaugeColor: _vm.memoryGauge.color,
                  },
                },
                [
                  _c("p", { staticClass: "percentage" }, [
                    _vm._v(_vm._s(_vm.memoryGauge.value) + "%"),
                  ]),
                ]
              ),
              _c("small", [_vm._v(_vm._s(_vm.getMemoryGaugeLabel()))]),
            ],
            1
          ),
          _c("div", [
            _c("div", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("widgets.glances.system-load-desc"),
                  expression: "$t('widgets.glances.system-load-desc')",
                },
              ],
              staticClass: "load-chart",
              attrs: { id: _vm.cpuLoadChartId },
            }),
          ]),
        ]),
        _c("div", [
          _c("hr"),
          _c("p", [
            _c("i", { staticClass: "fal fa-server" }),
            _c("strong", [_vm._v("Nextcloud")]),
            _c("em", [_vm._v(_vm._s(_vm.server.nextcloud.system.version))]),
            _vm._v(" "),
            _c("small", [_vm._v("• ")]),
            _c("strong", [
              _vm._v(_vm._s(_vm.server.server.webserver) + "/PHP"),
            ]),
            _c("em", [_vm._v(_vm._s(_vm.server.server.php.version))]),
          ]),
          _c("hr"),
          _c("p", [
            _c("i", { staticClass: "fal fa-database" }),
            _c("strong", [_vm._v(_vm._s(_vm.server.server.database.type))]),
            _c("em", [_vm._v(_vm._s(_vm.server.server.database.version))]),
            _vm._v(" "),
            _c("small", [_vm._v(_vm._s(_vm.tt("using")))]),
            _c("em", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.convertBytes(_vm.server.server.database.size)
                ),
              },
            }),
          ]),
          _c("hr"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }